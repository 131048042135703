// extracted by mini-css-extract-plugin
export var h100 = "main-module--h100--2boqf";
export var container = "main-module--container--2T5Lp";
export var logoBlock = "main-module--logoBlock--wUI4t";
export var navMenu = "main-module--navMenu--2KPI_";
export var open = "main-module--open--xQSKt";
export var contactButton = "main-module--contactButton--14Aty";
export var btn__headerWhite = "main-module--btn__headerWhite--2OoOP";
export var mobileMenu = "main-module--mobileMenu--3yBy0";
export var menuIcon = "main-module--menuIcon--2KrpC";
export var firstSection = "main-module--firstSection--20Ful";
export var sectionTitle = "main-module--sectionTitle--1QpRk";
export var flexBox = "main-module--flexBox--32phG";
export var w50 = "main-module--w50--A_HpC";
export var btn = "main-module--btn--2qtPJ";
export var btn__headerBlue = "main-module--btn__headerBlue--3bFK0";
export var btn__headerGreen = "main-module--btn__headerGreen--2Z6Ei";
export var relative = "main-module--relative--TZbES";
export var error404 = "main-module--error404--2ktGe";
export var textBlock = "main-module--textBlock--ldvl4";
export var policy = "main-module--policy--2ethh";