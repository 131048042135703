import React,{useState} from 'react'
import * as styles from '../pages/styles/main.module.scss'
import Logo from '../images/logo.png'
import { Link } from 'gatsby'

const Header = ({pageTitle}) => {
    const [clickMenu, setClickMenu] = useState({
        click: false
    })

    const handleClickMenu = e => {
        
        setClickMenu({
            click: !clickMenu.click
        })
    }
    return(
        <header>
            <div className={styles.container}>
                <div className={styles.logoBlock}>
                <Link to="/">
                    <img src={Logo} alt={pageTitle}/>
                </Link>
                </div>
                <nav className={`${styles.navMenu} ${clickMenu.click===true? styles.open: null}`}> 
                    <ul>
                        <li>
                            <Link to="/">Start</Link>
                        </li>
                        <li>
                            <Link to="/oferta">Oferta</Link>
                        </li>
                        <li> 
                            <Link to="/wspolpraca">Współpraca</Link>
                        </li>
                        <li>    
                            <Link to="/kontakt">Kontakt</Link>
                        </li>
                    </ul>
                </nav>
                <div className={styles.contactButton}>
                <Link to="/kontakt"><button className={`${styles.btn} ${pageTitle==='main'? styles.btn__headerWhite : styles.btn__headerBlue}`}>Napisz do nas</button></Link>
                </div>
                <div className={`${styles.mobileMenu} ${clickMenu.click===true? styles.open: null}`} onClick={(e) => handleClickMenu(e)}>
                    <span className={styles.menuIcon}></span>
                </div>
            </div>
        </header>
    )
}

export default Header;