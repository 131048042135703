import * as React from 'react'
import Helmet from 'react-helmet'
import Header from './header'
import Footer from './footer/footer'
import { favicon } from '../images/image'

const Layout = ({pageTitle, children}) => {
    return (
        <div>
           <Helmet>
               {pageTitle==='main' ? 
               <title>Aliatan biuro rachunkowe</title>
            :
            <title>{pageTitle} - Aliatan biuro rachunkowe </title>
            }

            <link rel="icon" type="image/png" href={favicon} />
            <script type="application/ld+json">
                {`
                    {
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "url": "https://bofaliatan.pl,
                    "name": "Aliatan - Biuro Obsługi Firm",
                    "contactPoint": {
                        "@type": "ContactPoint",
                        "telephone": "+48 627390065",
                        "contactType": "Biuro Aliatan"
                    }
                    }
                `}
            </script>
           
           </Helmet>
            <Header pageTitle={pageTitle}/>
            <main>
                {children}
            </main>
           <Footer pageTitle={pageTitle}/>
        </div>
    )
}
export default Layout;