import React from 'react'
import { Link } from 'gatsby'
import * as footerStyle from './footer.module.scss'
import * as mainStyle from '../../pages/styles/main.module.scss' 
import Logo from '../../images/logoW.png'
import {kodito} from '../../images/image'

const Footer = ({pageTitle}) => {
    const date = new Date();
    let today = date.getFullYear; 
    return(
        <>
    <footer className={footerStyle.footer}>
       <div className={`${mainStyle.container} ${footerStyle.flexible}`}>
        
        <div className={footerStyle.logoImage}>
        <Link to="/">
            <img src={Logo} alt={pageTitle} />
        </Link>
        </div>

        <div className={footerStyle.menuList}>
            <ul>
           
                        <li>
                            <Link to="/oferta">Oferta</Link>
                        </li>
                        <li>    
                            <Link to="/kontakt">Kontakt</Link>
                        </li>
                        <li> 
                            <Link to="/wspolpraca">Współpraca</Link>
                        </li>
                        <li>    
                            <Link to="/polityka-prywatnosci">Polityka prywatności</Link>
                        </li>
            </ul>
        </div>

        <div className={footerStyle.contact}>
            <div className={footerStyle.email}>
                <a href="mailto:biuro.aliatan@wp.pl">biuro.aliatan@wp.pl</a>
            </div>
            <div className={footerStyle.phone}>
                <a href="tel:627390065">62 73 90 065</a>
            </div>
        </div>
       </div>

       
    </footer>
    <div className={mainStyle.container}>
    <div className={footerStyle.footerSmall}>
            <div>
                <p>Wszelkie prawa zastrzeżone © bofaliatan.pl {today}</p></div>
            <div className={footerStyle.company}>
                <a href="https://kodito.pl">
                    <img src={kodito}/>
                </a>
            </div>
    </div>
   </div>
   </>
    )
}

export default Footer;